import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactThanksPage = () => (
  <Layout>
    <SEO title="Contact Thanks" />
    <h1 className="title">Thanks for contacting me</h1>
    <hr />
    <p>I'll get back to you within a few hours.</p>
  </Layout>
);

export default ContactThanksPage;
